@import "reset.scss";
@import "animate.min.css";
@import "component-styles/_index.scss";

/*GLOBAL STYLES*/
* {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

*::-webkit-media-controls-panel {
  display: none!important;
  -webkit-appearance: none;
}

*::-webkit-media-controls-play-button {
  display: none!important;
  -webkit-appearance: none;
}

*::-webkit-media-controls-start-playback-button {
  display: none!important;
  -webkit-appearance: none;
}

html,
body {
  width: 100%;
  height: 100%;
}
body {
  letter-spacing: 0.5px;
}
.clear {
  clear: both;
}
.wrapper {
  position: relative;
  margin: 0 auto;
  width: 1440px;
  max-width: 100%;
}
#container {
  margin: 0 auto;
  background: #fff;
  overflow: hidden;
}

#container .screen-reader {
  width: 0px;
  height: 0px;
  border: 0px;
  margin: 0px;
  padding: 0px;
  position: absolute;
  font-size: 0px;
  speak: normal;
}

#maincontent {
  position: relative;
  padding-top: 60px;
  font-family: $fontFamilySans2;
  font-weight: 500;
}

/* Footer */
.footer {
  background: #000 !important;
}

@import "global-sponsored-headers.scss";
