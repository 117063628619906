/* HEADERS */
.global-header {
  margin-top: 0;
  border-bottom: 1px solid #e5e5e5 !important;
  -webkit-transition: 1.2s;
  transition: 1.2s;
}
.global-header.scrolling,
#progress.scrolling {
  margin-top: -72px;
}
#secondary-header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 60px;
  font-family: "din-2014", "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-align: center;
  color: #fff;
  background: #090909;
  border-bottom: 1px solid #3f3f3f;
  z-index: 9999;
}
#secondary-header a,
#secondary-header a:hover {
  color: #fff;
}
#secondary-header.fixed {
  position: fixed;
}
.secondary-sponsored {
  display: block;
  position: relative;
  width: 100%;
  height: 20px;
  font-size: 12px;
  font-weight: 700;
  line-height: 20px;
  text-transform: uppercase;
  color: #000;
  background: #f5f5f5;
}
#secondary-header .secondary-sponsored a,
#secondary-header .secondary-sponsored a:hover {
  color: #000;
}
.mobile-show {
  display: block;
}
.crafted {
  display: inline-block;
  position: absolute;
  top: 20px;
  left: 15px;
  font-size: 14px;
  line-height: 40px;
  font-weight: 400;
  text-align: left;
  text-transform: uppercase;
  color: #fff;
}
.crafted span {
  font-weight: 700;
}
.crafted .logo {
  display: inline-block;
  width: 150px;
  height: 13px;
  margin-left: 0.5em;
}
.secondary-presented {
  display: inline-block;
  position: relative;
  width: 79px;
}
.secondary-presented .logo {
  margin-top: 6px;
  width: 100%;
}

/* Social */
#social {
  position: absolute;
  top: 20px;
  right: 0;
  height: 40px;
  font-size: 14px;
  font-weight: 800;
  text-align: center;
  z-index: 10;
  display: flex;
}

#social .share {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 39px;
  text-align: center;
  border-left: 1px solid #3f3f3f;

  /* Iphone 5 issues */
  @media (max-width: 320px) {
    width: 30px;
    height: 39px;
  }
}

#social svg {
  width: 18px;
  height: 18px;
  margin-top: 11px;
}
.share.facebook:hover {
  background: #3b5998;
}
.share.twitter:hover {
  background: #1da1f2;
}
.share.linkedin:hover {
  background: #0a66c2;
}
/* Progress Bar */
.progress-container {
  position: absolute;
  bottom: -4px;
  width: 100%;
  height: 4px;
}
.progress-bar {
  background: $red;
  width: 0%;
  height: 4px;
}

@media screen and (max-width: 1024px) {
  .crafted {
    left: 15px;
  }
}

@media screen and (max-width: 479px) {
  .crafted {
    font-size: 10px;
  }
  .crafted span {
    font-weight: 400;
    letter-spacing: .2em;
  }
}
