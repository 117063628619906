.footer-block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 60px 24px;
  color: #fff;
  background: #090909;
  z-index: 2;

  &__logo {
    width: 212px;
    margin-bottom: 40px;
  }

  &__copy {
    max-width: 800px;
    margin: 0 auto 20px;
    font-family: 'Lato', sans-serif;
  	font-size: 16px;
  	line-height: 1.6em;
  	text-align: center;

    a {
      color: #fff;
      text-decoration: underline;

      &:hover,
      &:focus {
        color: #fff;
        text-decoration: none;
      }
    }
  }

  .social-icon-row {
    margin-bottom: 42px;
    display: flex;
  }

  .social-icon {
    margin: 0px 10px;
    height: 60px;
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 2px solid $red;
    background-color: $red;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0px 12px;

    &:hover {
      background-color: transparent;
      border: 2px solid $white;
    }

    @media (max-width: 768px) {
      height: 44px;
      width: 44px;
      margin: 0px 12px;

      img {
        height: 23px;
      }
    }


  }
}
