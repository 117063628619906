.article-block {
  .collapsed-explore-nav {
    ol {
      display: flex;
      flex-direction: row;
      align-items: stretch;

      @media screen and (max-width: 768px) {
        width: 100%;
      }

      li {
        padding: 40px 40px 20px 200px;
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 50%;
        min-height: 100%;

        &:last-child {
          border-left: 1px solid #CFCFCF;
        }

        @media screen and (max-width: 768px) {
          padding: 20px 40px;
          align-items: center;
          justify-content: center;
          min-height: auto;
        }

        a {
          color: $black;
        }

        .label {
          font: 700 35px $fontFamilySerif;
          display: flex;
          flex-direction: column;
          color: $black;
          line-height: 100%;
          margin-top: 6px;
          margin-bottom: 24px;
          z-index: 1;

          @media screen and (max-width: 768px) {
            margin: 0;
            font-size: 15px;
            align-items: center;
            justify-content: center;
            text-align: center;
          }

          .eyebrow {
            font: 12px $fontFamilySans1;
            font-weight: 700;
            text-transform: uppercase;
            letter-spacing: 5px;

            @media screen and (max-width: 768px) {
              display: none;
            }
          }
        }

        .index {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          z-index: 2;
          left: 100px;
          font: 12px $fontFamilySans1;
          font-weight: 700;
          letter-spacing: 5px;
          width: 73px;
          height: 44px;
          padding: 17px 0 0 13px;
          background: url(../img/article-number.svg) no-repeat center center;
          background-size: contain;

          @media screen and (max-width: 1024px) {
            left: 30px;
          }

          @media screen and (max-width: 768px) {
            display: none;
          }
        }
      }
    }
  }
}

#explore {
  background-color: $white;
  width: 100%;
  position: relative;
  z-index: 999;

  nav {
    width: 100%;

    ol {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    li {
      position: relative;
      width: 100%;
      height: 200px;
      cursor: pointer;
      background-color: #fff;
      border-bottom: 1px solid #CFCFCF;

      @media screen and (max-width: 768px) {
        flex-wrap: wrap;
        padding-right: 40px;
        padding-left: 120px;
      }

      a {
        color: $black;
        display: flex;
        flex-direction: row;
        align-items: center;
        align-content: center;
        width: 100%;
        height: 100%;
        padding: 0 200px;
      }

      h2 {
        font: 900 60px $fontFamilySerif;
        display: flex;
        flex-direction: column;
        width: 100%;
        min-width: 100%;
        color: $black;
        line-height: 100%;
        margin-top: 6px;
        margin-bottom: 24px;
        z-index: 1;

        @media screen and (max-width: 768px) {
          font-size: 28px;
        }

        .eyebrow {
          font: 12px $fontFamilySans1;
          font-weight: 700;
          text-transform: uppercase;
          letter-spacing: 5px;

          @media screen and (max-width: 768px) {
            font-size: 8px;
          }
        }
      }

      .index,
      .btn-watch {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 2;
      }

      .index {
        left: 100px;
        font: 12px $fontFamilySans1;
        font-weight: 700;
        letter-spacing: 5px;
        width: 73px;
        height: 44px;
        padding: 17px 0 0 13px;
        background: url(../img/article-number.svg) no-repeat center center;
        background-size: contain;

        @media screen and (max-width: 1024px) {
          left: 30px;
        }

        @media screen and (max-width: 768px) {
          left: 30px;
        }
      }

      .video-bg {
        display: none;
        width: 100%;
        position: absolute;
        top: -50%;
        left: 0;
        z-index: -1;

        &::after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(37,39,39, 0.4);
          z-index: 1;
        }

        video {
          width: 100%;
          height: auto;
        }
      }

      .btn-watch {
        color: $white;
        text-transform: uppercase;
        border: 1px solid $red;
        border-radius: 100px;
        font-size: 12px;
        line-height: 100%;
        letter-spacing: 5px;
        padding: 16px 27px;
        right: 100px;
        opacity: 0;

        &:hover {
          background-color: $red;
        }

        @media screen and (max-width: 768px) {
          position: relative;
          top: unset;
          right: unset;
          font-size: 8px;
          font-weight: 800;
          color: #252727;
          letter-spacing: 3px;
          padding: 8px 12px;
          opacity: 1;
        }
      }

      &.expanded {
        background-color: transparent;
        color: $white;
        height: 400px;
        overflow: hidden;

        @media screen and (max-width: 768px) {
          height: 200px;
        }

        a {
          color: $white;
          height: 100%;
        }

        h2 {
          color: $white;
        }

        .index {
          background-image: url(../img/article-number-expanded.svg);
        }

        .video-bg {
          display: block;

          @media screen and (max-width: 768px) {
            display: none;
          }
        }

        .btn-watch {
          opacity: 1;
        }
      }
    }
  }
}
