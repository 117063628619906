.content-block {
  position: relative;
  z-index: 3;

  a,
  a:active,
  a:visited,
  a:link {
    color: $red;
    font-weight: 500;
    text-decoration: underline;
  }
  a:hover{
    text-decoration: none;
  }

  h2 {
    margin: 0 0 50px;
    color: #252727;
    font-family: $fontFamilySans1;
    font-size: 55px;
    font-weight: 900;
    line-height: 1.2em;
    text-transform:uppercase;
    transform: translateX(-20vw);
    opacity: 0;

    @media screen and (max-width: 1200px) {
      padding: 0 30px;
    }

    @media screen and (max-width: 1024px) {
      margin: 0 0 20px;
    }

    @media screen and (max-width: 768px) {
      padding: 0;
    }
  }

  p {
    position: relative;
    font-size: 18px;
    font-weight: 300;
    line-height: 1.6em;
    padding: 0 300px 2em;
    opacity: 0;

    @media screen and (max-width: 768px) {
      padding: 0 0 1.6em 0;
    }

    @media screen and (max-width: 415px) {
      font-size: 14px;
    }
  }

  .wrapper {
    width: 1200px;
    max-width: 100%;
    margin: 0 auto;
    padding: 0 0 115px;

    @media screen and (max-width: 768px) {
      padding: 0 60px 95px;
    }

    @media screen and (max-width: 415px) {
      padding: 0 30px 55px;
    }
  }

  .first-character {
    position: absolute;
    top: -20px;
    left: 170px;
    color: transparent;
    font-size: 180px;
    line-height: 1em;
    font-weight: 700;
    font-family: $fontFamilySerif;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: $white;

    @media screen and (max-width: 1024px) {
      left: 30px;
    }

    @media screen and (max-width: 768px) {
      position: relative;
      top: auto;
      left: auto;
      color: $white;
      font: 300 18px $fontFamilySans2;
      font-size: 18px;
      font-weight: 300;
      line-height: 1.6em;
      -webkit-text-stroke-width: unset;
      -webkit-text-stroke-color: unset;
    }

    .red-line {
      position: absolute;
      top: 40px;
      left: -620px;
      width: 600px;
      height: 1px;
      background: $red;
      transform: scaleX(0);
      transform-origin: right top;

      @media screen and (max-width: 1024px) {
        display: none;
      }
    }
  }

  &.intro {
    .wrapper {
      padding: 60px 0 115px;

      @media screen and (max-width: 768px) {
        padding: 120px 60px 95px;
      }

      @media screen and (max-width: 415px) {
        padding: 80px 30px 55px;
      }

      p {
        @media screen and (max-width: 1024px) {
          padding: 0 100px 2em 160px;
        }

        @media screen and (max-width: 768px) {
          padding: 2em;
        }
      }
    }
  }
}

#intro-block {
  position: relative;
  color: #fff;
}
