#container {
  .video-block {
    margin-left: calc(50% - 50vw);
    margin-right: calc(50% - 50vw);

    .wrapper {
      width: 1200px;
      max-width: 100%;
      padding: 0 0 140px;

      @media screen and (max-width: 1200px) {
        width: 100%;
        padding: 0 30px 140px;
      }

      @media screen and (max-width: 768px) {
        width: 100%;
        padding: 0 60px 60px;
      }

      @media screen and (max-width: 415px) {
        padding: 0 30px 40px;
      }
    }

    .video-responsive {
    	position:relative;
    	width: 100%;
    	height: 0;
    	padding-top: 56.25%;
    }

    .background-text {
      font: 270px/90% $fontFamilySerif;
      font-weight: 700;
      white-space: nowrap;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      @media screen and (max-width: 768px) {
        font-size: 70px;
      }
    }

    .video-player {
      // hide things
      overflow: revert;
      padding-bottom: 20px;

      .vjs-control-bar {
        width: auto;
        right: unset;
        left: 50%;
        transform: translate(-50%, 50%);
        background-color: $red;
        border-radius: 100px;

        @media screen and (max-width: 768px) {
          transform-origin: center center;
          transform: translate(-50%, 50%) scale(.75);
        }

        .vjs-control,
        .vjs-time-divider {
          display: none;
        }

        .vjs-play-control,
        .vjs-current-time,
        .vjs-volume-panel,
        .vjs-mute-control {
          display: initial;
        }

        .vjs-volume-panel.vjs-hover {
          width: auto;
        }

        .vjs-current-time {
          margin-left: 0;
        }

      }
    }
  }
}
