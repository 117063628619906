.hero-block {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: calc(100vh - 102px);
  background: #000;
  z-index: 1;

  @media screen and (max-width: 1024px) {
    padding: 0 60px;
  }

  @media screen and (max-width: 768px) {
    height: 100vh;
    padding: 0 30px;
  }

  @media screen and (max-width: 415px) {
    justify-content: flex-start;
    padding: 100px 30px 0;
    background: url('../img/hero-bg-mobile.jpg') no-repeat;
    background-size: cover;
  }

  .hero-video {
    position: fixed;
    top: 160px;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    z-index: -2;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(37,39,39, 0.4);
      z-index: 1;
    }

    #video_bg {
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;

      @media screen and (max-width: 415px) {
        display: none;
      }

      @media (min-aspect-ratio: 16/9) {
        height: 300%;
        top: -100%;
      }
      @media (max-aspect-ratio: 16/9) {
        width: 300%;
        left: -100%;
      }
      @supports (object-fit: cover) {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .hero-wrapper {
    position: relative;
    width: 1100px;
    max-width: 100%;
    margin: 0 auto;
    padding: 0 60px;
    z-index: 1;

    @media screen and (max-width: 768px) {
      width: 100%;
      padding: 0;
    }

    h1 {
      margin: 20px 0;
      color: #fff;
      font-family: $fontFamilySerif;
      font-size: 120px;
      font-weight: 700;
      line-height: 100%;
      text-transform: capitalize;
      opacity: 0;

      @media screen and (max-width: 1024px) {
        font-size: 60px;
      }

      @media screen and (max-width: 768px) {
        font-size: 48px;
      }
    }

    .subtitle {
      width: 585px;
      max-width: 100%;
      color: #fff;
      font-size: 30px;
      font-weight: 500;
      line-height: 140%;
      letter-spacing: -.5px;
      opacity: 0;

      @media screen and (max-width: 768px) {
        font-size: 16px;
      }
    }

    .info {
      display: flex;
      flex-direction: row;
      align-items: center;
      opacity: 0;

      @media screen and (max-width: 768px) {
        max-width: 280px;
      }
    }

    .divider {
      border-top: 1px solid $red;
      height: 1px;
      width: 175px;
      margin: 0 20px 0 19px;

      @media screen and (max-width: 768px) {
        width: 100%;
      }
    }

    .date,
    .author {
      color: #fff;
      font-family: $fontFamilySans1;
      font-size: 12px;
      font-weight: 700;
      text-transform: uppercase;
      letter-spacing: 5px;

      @media screen and (max-width: 768px) {
        font-size: 8px;
        white-space: nowrap;
      }
    }

    .scroll {
      width: 44px;
      height: 77px;
      position: absolute;
      top: 50%;
      right: 10%;
      transform: translateY(-50%);
      opacity: 0;
      z-index: 2;

      @media screen and (max-width: 768px) {
        position: relative;
        transform: none;
        right: auto;
        top: auto;
      }

      .scroll-arrow {
        position: absolute;
        top: 12px;
        left: calc(50% - 2px);
        width: 8px;
        height: 46px;
        background: url('../img/scroll-arrow.svg') no-repeat;
        background-size: contain;
      }

      .scroll-ellipse {
        position: absolute;
        bottom: 0;
        width: 46px;
        height: 45px;
        background: url('../img/scroll-ellipse.svg') no-repeat;
        background-size: contain;
      }
    }
  }

  .stop-scrolling {
    height: 100%;
    overflow: hidden;
    touch-action: none;
  }

}
