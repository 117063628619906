/**
 * html5doctor.com Reset Stylesheet v1.6.1 (http://html5doctor.com/html-5-reset-stylesheet/)
 * Richard Clark (http://richclarkdesign.com)
 * http://cssreset.com
 */
@import "animate.min.css";
html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

body {
  line-height: 1;
  letter-spacing: 0;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

nav ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

#intro :focus, #intro a:focus, #intro a:active, #overlay :focus, #overlay a:focus, #overlay a:active, video-js :focus {
  outline: 1px solid #fff !important;
}

:focus, a:focus, a:active {
  outline: 1px solid #333 !important;
}

/* change colours to suit your needs */
ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none;
}

/* change colours to suit your needs */
mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold;
}

del {
  text-decoration: line-through;
}

abbr[title], dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 0 0 15px;
  padding: 0;
}

input, select {
  vertical-align: middle;
}

.hero-block {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: calc(100vh - 102px);
  background: #000;
  z-index: 1;
}
@media screen and (max-width: 1024px) {
  .hero-block {
    padding: 0 60px;
  }
}
@media screen and (max-width: 768px) {
  .hero-block {
    height: 100vh;
    padding: 0 30px;
  }
}
@media screen and (max-width: 415px) {
  .hero-block {
    justify-content: flex-start;
    padding: 100px 30px 0;
    background: url("../img/hero-bg-mobile.jpg") no-repeat;
    background-size: cover;
  }
}
.hero-block .hero-video {
  position: fixed;
  top: 160px;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  z-index: -2;
}
.hero-block .hero-video::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(37, 39, 39, 0.4);
  z-index: 1;
}
.hero-block .hero-video #video_bg {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
@media screen and (max-width: 415px) {
  .hero-block .hero-video #video_bg {
    display: none;
  }
}
@media (min-aspect-ratio: 16/9) {
  .hero-block .hero-video #video_bg {
    height: 300%;
    top: -100%;
  }
}
@media (max-aspect-ratio: 16/9) {
  .hero-block .hero-video #video_bg {
    width: 300%;
    left: -100%;
  }
}
@supports (object-fit: cover) {
  .hero-block .hero-video #video_bg {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.hero-block .hero-wrapper {
  position: relative;
  width: 1100px;
  max-width: 100%;
  margin: 0 auto;
  padding: 0 60px;
  z-index: 1;
}
@media screen and (max-width: 768px) {
  .hero-block .hero-wrapper {
    width: 100%;
    padding: 0;
  }
}
.hero-block .hero-wrapper h1 {
  margin: 20px 0;
  color: #fff;
  font-family: "Jubilat", serif;
  font-size: 120px;
  font-weight: 700;
  line-height: 100%;
  text-transform: capitalize;
  opacity: 0;
}
@media screen and (max-width: 1024px) {
  .hero-block .hero-wrapper h1 {
    font-size: 60px;
  }
}
@media screen and (max-width: 768px) {
  .hero-block .hero-wrapper h1 {
    font-size: 48px;
  }
}
.hero-block .hero-wrapper .subtitle {
  width: 585px;
  max-width: 100%;
  color: #fff;
  font-size: 30px;
  font-weight: 500;
  line-height: 140%;
  letter-spacing: -0.5px;
  opacity: 0;
}
@media screen and (max-width: 768px) {
  .hero-block .hero-wrapper .subtitle {
    font-size: 16px;
  }
}
.hero-block .hero-wrapper .info {
  display: flex;
  flex-direction: row;
  align-items: center;
  opacity: 0;
}
@media screen and (max-width: 768px) {
  .hero-block .hero-wrapper .info {
    max-width: 280px;
  }
}
.hero-block .hero-wrapper .divider {
  border-top: 1px solid #DE2628;
  height: 1px;
  width: 175px;
  margin: 0 20px 0 19px;
}
@media screen and (max-width: 768px) {
  .hero-block .hero-wrapper .divider {
    width: 100%;
  }
}
.hero-block .hero-wrapper .date,
.hero-block .hero-wrapper .author {
  color: #fff;
  font-family: "Greycliff-CF", sans-serif;
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 5px;
}
@media screen and (max-width: 768px) {
  .hero-block .hero-wrapper .date,
.hero-block .hero-wrapper .author {
    font-size: 8px;
    white-space: nowrap;
  }
}
.hero-block .hero-wrapper .scroll {
  width: 44px;
  height: 77px;
  position: absolute;
  top: 50%;
  right: 10%;
  transform: translateY(-50%);
  opacity: 0;
  z-index: 2;
}
@media screen and (max-width: 768px) {
  .hero-block .hero-wrapper .scroll {
    position: relative;
    transform: none;
    right: auto;
    top: auto;
  }
}
.hero-block .hero-wrapper .scroll .scroll-arrow {
  position: absolute;
  top: 12px;
  left: calc(50% - 2px);
  width: 8px;
  height: 46px;
  background: url("../img/scroll-arrow.svg") no-repeat;
  background-size: contain;
}
.hero-block .hero-wrapper .scroll .scroll-ellipse {
  position: absolute;
  bottom: 0;
  width: 46px;
  height: 45px;
  background: url("../img/scroll-ellipse.svg") no-repeat;
  background-size: contain;
}
.hero-block .stop-scrolling {
  height: 100%;
  overflow: hidden;
  touch-action: none;
}

.article-block .collapsed-explore-nav ol {
  display: flex;
  flex-direction: row;
  align-items: stretch;
}
@media screen and (max-width: 768px) {
  .article-block .collapsed-explore-nav ol {
    width: 100%;
  }
}
.article-block .collapsed-explore-nav ol li {
  padding: 40px 40px 20px 200px;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 50%;
  min-height: 100%;
}
.article-block .collapsed-explore-nav ol li:last-child {
  border-left: 1px solid #CFCFCF;
}
@media screen and (max-width: 768px) {
  .article-block .collapsed-explore-nav ol li {
    padding: 20px 40px;
    align-items: center;
    justify-content: center;
    min-height: auto;
  }
}
.article-block .collapsed-explore-nav ol li a {
  color: #252727;
}
.article-block .collapsed-explore-nav ol li .label {
  font: 700 35px "Jubilat", serif;
  display: flex;
  flex-direction: column;
  color: #252727;
  line-height: 100%;
  margin-top: 6px;
  margin-bottom: 24px;
  z-index: 1;
}
@media screen and (max-width: 768px) {
  .article-block .collapsed-explore-nav ol li .label {
    margin: 0;
    font-size: 15px;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
}
.article-block .collapsed-explore-nav ol li .label .eyebrow {
  font: 12px "Greycliff-CF", sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 5px;
}
@media screen and (max-width: 768px) {
  .article-block .collapsed-explore-nav ol li .label .eyebrow {
    display: none;
  }
}
.article-block .collapsed-explore-nav ol li .index {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  left: 100px;
  font: 12px "Greycliff-CF", sans-serif;
  font-weight: 700;
  letter-spacing: 5px;
  width: 73px;
  height: 44px;
  padding: 17px 0 0 13px;
  background: url(../img/article-number.svg) no-repeat center center;
  background-size: contain;
}
@media screen and (max-width: 1024px) {
  .article-block .collapsed-explore-nav ol li .index {
    left: 30px;
  }
}
@media screen and (max-width: 768px) {
  .article-block .collapsed-explore-nav ol li .index {
    display: none;
  }
}

#explore {
  background-color: #fff;
  width: 100%;
  position: relative;
  z-index: 999;
}
#explore nav {
  width: 100%;
}
#explore nav ol {
  display: flex;
  flex-direction: column;
  align-items: center;
}
#explore nav li {
  position: relative;
  width: 100%;
  height: 200px;
  cursor: pointer;
  background-color: #fff;
  border-bottom: 1px solid #CFCFCF;
}
@media screen and (max-width: 768px) {
  #explore nav li {
    flex-wrap: wrap;
    padding-right: 40px;
    padding-left: 120px;
  }
}
#explore nav li a {
  color: #252727;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  width: 100%;
  height: 100%;
  padding: 0 200px;
}
#explore nav li h2 {
  font: 900 60px "Jubilat", serif;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 100%;
  color: #252727;
  line-height: 100%;
  margin-top: 6px;
  margin-bottom: 24px;
  z-index: 1;
}
@media screen and (max-width: 768px) {
  #explore nav li h2 {
    font-size: 28px;
  }
}
#explore nav li h2 .eyebrow {
  font: 12px "Greycliff-CF", sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 5px;
}
@media screen and (max-width: 768px) {
  #explore nav li h2 .eyebrow {
    font-size: 8px;
  }
}
#explore nav li .index,
#explore nav li .btn-watch {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
}
#explore nav li .index {
  left: 100px;
  font: 12px "Greycliff-CF", sans-serif;
  font-weight: 700;
  letter-spacing: 5px;
  width: 73px;
  height: 44px;
  padding: 17px 0 0 13px;
  background: url(../img/article-number.svg) no-repeat center center;
  background-size: contain;
}
@media screen and (max-width: 1024px) {
  #explore nav li .index {
    left: 30px;
  }
}
@media screen and (max-width: 768px) {
  #explore nav li .index {
    left: 30px;
  }
}
#explore nav li .video-bg {
  display: none;
  width: 100%;
  position: absolute;
  top: -50%;
  left: 0;
  z-index: -1;
}
#explore nav li .video-bg::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(37, 39, 39, 0.4);
  z-index: 1;
}
#explore nav li .video-bg video {
  width: 100%;
  height: auto;
}
#explore nav li .btn-watch {
  color: #fff;
  text-transform: uppercase;
  border: 1px solid #DE2628;
  border-radius: 100px;
  font-size: 12px;
  line-height: 100%;
  letter-spacing: 5px;
  padding: 16px 27px;
  right: 100px;
  opacity: 0;
}
#explore nav li .btn-watch:hover {
  background-color: #DE2628;
}
@media screen and (max-width: 768px) {
  #explore nav li .btn-watch {
    position: relative;
    top: unset;
    right: unset;
    font-size: 8px;
    font-weight: 800;
    color: #252727;
    letter-spacing: 3px;
    padding: 8px 12px;
    opacity: 1;
  }
}
#explore nav li.expanded {
  background-color: transparent;
  color: #fff;
  height: 400px;
  overflow: hidden;
}
@media screen and (max-width: 768px) {
  #explore nav li.expanded {
    height: 200px;
  }
}
#explore nav li.expanded a {
  color: #fff;
  height: 100%;
}
#explore nav li.expanded h2 {
  color: #fff;
}
#explore nav li.expanded .index {
  background-image: url(../img/article-number-expanded.svg);
}
#explore nav li.expanded .video-bg {
  display: block;
}
@media screen and (max-width: 768px) {
  #explore nav li.expanded .video-bg {
    display: none;
  }
}
#explore nav li.expanded .btn-watch {
  opacity: 1;
}

.content-block {
  position: relative;
  z-index: 3;
}
.content-block a,
.content-block a:active,
.content-block a:visited,
.content-block a:link {
  color: #DE2628;
  font-weight: 500;
  text-decoration: underline;
}
.content-block a:hover {
  text-decoration: none;
}
.content-block h2 {
  margin: 0 0 50px;
  color: #252727;
  font-family: "Greycliff-CF", sans-serif;
  font-size: 55px;
  font-weight: 900;
  line-height: 1.2em;
  text-transform: uppercase;
  transform: translateX(-20vw);
  opacity: 0;
}
@media screen and (max-width: 1200px) {
  .content-block h2 {
    padding: 0 30px;
  }
}
@media screen and (max-width: 1024px) {
  .content-block h2 {
    margin: 0 0 20px;
  }
}
@media screen and (max-width: 768px) {
  .content-block h2 {
    padding: 0;
  }
}
.content-block p {
  position: relative;
  font-size: 18px;
  font-weight: 300;
  line-height: 1.6em;
  padding: 0 300px 2em;
  opacity: 0;
}
@media screen and (max-width: 768px) {
  .content-block p {
    padding: 0 0 1.6em 0;
  }
}
@media screen and (max-width: 415px) {
  .content-block p {
    font-size: 14px;
  }
}
.content-block .wrapper {
  width: 1200px;
  max-width: 100%;
  margin: 0 auto;
  padding: 0 0 115px;
}
@media screen and (max-width: 768px) {
  .content-block .wrapper {
    padding: 0 60px 95px;
  }
}
@media screen and (max-width: 415px) {
  .content-block .wrapper {
    padding: 0 30px 55px;
  }
}
.content-block .first-character {
  position: absolute;
  top: -20px;
  left: 170px;
  color: transparent;
  font-size: 180px;
  line-height: 1em;
  font-weight: 700;
  font-family: "Jubilat", serif;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #fff;
}
@media screen and (max-width: 1024px) {
  .content-block .first-character {
    left: 30px;
  }
}
@media screen and (max-width: 768px) {
  .content-block .first-character {
    position: relative;
    top: auto;
    left: auto;
    color: #fff;
    font: 300 18px "Bilo", sans-serif;
    font-size: 18px;
    font-weight: 300;
    line-height: 1.6em;
    -webkit-text-stroke-width: unset;
    -webkit-text-stroke-color: unset;
  }
}
.content-block .first-character .red-line {
  position: absolute;
  top: 40px;
  left: -620px;
  width: 600px;
  height: 1px;
  background: #DE2628;
  transform: scaleX(0);
  transform-origin: right top;
}
@media screen and (max-width: 1024px) {
  .content-block .first-character .red-line {
    display: none;
  }
}
.content-block.intro .wrapper {
  padding: 60px 0 115px;
}
@media screen and (max-width: 768px) {
  .content-block.intro .wrapper {
    padding: 120px 60px 95px;
  }
}
@media screen and (max-width: 415px) {
  .content-block.intro .wrapper {
    padding: 80px 30px 55px;
  }
}
@media screen and (max-width: 1024px) {
  .content-block.intro .wrapper p {
    padding: 0 100px 2em 160px;
  }
}
@media screen and (max-width: 768px) {
  .content-block.intro .wrapper p {
    padding: 2em;
  }
}

#intro-block {
  position: relative;
  color: #fff;
}

.articles {
  position: relative;
  background-color: #fff;
}
.articles .article-wrapper {
  display: none;
  flex-direction: column;
  align-items: center;
  width: 100%;
  transition: opacity 100ms ease-in-out;
}
.articles .article-wrapper.animating {
  opacity: 0;
}
.articles .article-wrapper.active {
  display: flex;
}
.articles .article-wrapper .article-block {
  display: flex;
  flex-direction: column;
  width: 1000px;
  max-width: 100%;
  border-bottom: 1px solid #CFCFCF;
  position: relative;
  padding: 0 0 90px;
  z-index: 2;
}
.articles .article-wrapper .article-block header,
.articles .article-wrapper .article-block .content-wrapper {
  padding: 0 100px;
}
@media screen and (max-width: 768px) {
  .articles .article-wrapper .article-block header,
.articles .article-wrapper .article-block .content-wrapper {
    padding: 0 30px;
  }
}
.articles .article-wrapper .article-block header {
  position: relative;
}
@media screen and (max-width: 768px) {
  .articles .article-wrapper .article-block header {
    padding-left: 120px;
  }
}
.articles .article-wrapper .article-block header h2 {
  font: 900 60px "Greycliff-CF", sans-serif;
  color: transparent;
  text-transform: uppercase;
  margin-top: 6px;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #252727;
}
@media screen and (max-width: 415px) {
  .articles .article-wrapper .article-block header h2 {
    font-family: "Jubilat", serif;
    font-weight: 700px;
    font-size: 28px;
    line-height: 90%;
    color: #252727;
    -webkit-text-stroke-width: 0;
  }
}
.articles .article-wrapper .article-block header .index {
  position: absolute;
  top: 50%;
  left: 0;
  font: 12px "Greycliff-CF", sans-serif;
  font-weight: 700;
  letter-spacing: 5px;
  width: 73px;
  height: 44px;
  padding: 17px 0 0 13px;
  background: url(../img/article-number.svg) no-repeat center center;
  background-size: contain;
  transform: translateY(-50%);
}
@media screen and (max-width: 1024px) {
  .articles .article-wrapper .article-block header .index {
    left: 20px;
  }
}
.articles .article-wrapper .article-block header .eyebrow {
  font: 12px "Greycliff-CF", sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 5px;
}
@media screen and (max-width: 768px) {
  .articles .article-wrapper .article-block header .eyebrow {
    font-size: 8px;
  }
}
.articles .article-wrapper .article-block header .btn-back {
  position: absolute;
  top: -40px;
  right: 60px;
  color: #DE2628;
  font-weight: 800;
  font-size: 12px;
  letter-spacing: 5px;
  text-transform: uppercase;
}
.articles .article-wrapper .article-block header .btn-back::before {
  content: "";
  display: inline-block;
  width: 37px;
  height: 8px;
  margin-right: 8px;
  background: center/contain url(../img/back-arrow.svg) no-repeat;
}
@media screen and (max-width: 768px) {
  .articles .article-wrapper .article-block header .btn-back {
    top: -10px;
  }
}
.articles .article-wrapper .article-block .content-wrapper p {
  margin: 2em 0;
  line-height: 160%;
}
@media screen and (max-width: 415px) {
  .articles .article-wrapper .article-block .content-wrapper p {
    font-size: 14px;
  }
}
.articles .article-wrapper .article-block .content-wrapper .quote-wrapper {
  font: 40px/120% "Jubilat", serif;
  font-weight: 400;
  margin: 76px -100px;
  position: relative;
  border: 1px solid #f1f1f1;
  text-align: center;
  padding: 59px 72px;
}
@media screen and (max-width: 1024px) {
  .articles .article-wrapper .article-block .content-wrapper .quote-wrapper {
    padding: 40px 25px 60px;
    margin: 60px auto 80px;
  }
}
.articles .article-wrapper .article-block .content-wrapper .quote-wrapper::before {
  content: "";
  position: absolute;
  top: -22px;
  left: calc(50% - 33px);
  width: 66px;
  height: 44px;
  background: #fff url(../img/quote.svg) no-repeat center center;
  background-size: contain;
}
.articles .article-wrapper .article-block .content-wrapper .quote-wrapper p {
  margin: 0;
  line-height: 120%;
  opacity: 0;
}
@media screen and (max-width: 1024px) {
  .articles .article-wrapper .article-block .content-wrapper .quote-wrapper p {
    font-weight: 600;
    font-size: 22px;
    line-height: 120%;
  }
}
.articles .article-wrapper .article-block .content-wrapper .quote-wrapper .citation {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -16px;
  text-align: center;
  opacity: 0;
}
@media screen and (max-width: 1024px) {
  .articles .article-wrapper .article-block .content-wrapper .quote-wrapper .citation {
    font-size: 10px;
    line-height: 120%;
    height: 5px;
    bottom: 0;
  }
}
.articles .article-wrapper .article-block .content-wrapper .quote-wrapper .citation span {
  font: 12px "Greycliff-CF", sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 5px;
  padding: 0 20px;
  background-color: #fff;
}
@media screen and (max-width: 1024px) {
  .articles .article-wrapper .article-block .content-wrapper .quote-wrapper .citation span {
    width: 90%;
    padding: 0 8px;
    display: inline-block;
  }
}
.articles .article-wrapper .article-block .content-wrapper .quote-wrapper .citation .dash {
  display: inline;
  color: #DE2628;
}
.articles .article-wrapper .article-block .content-wrapper .quote-wrapper.has-author-photo {
  font-size: 38px;
  padding-left: 200px;
  margin-left: 40px;
}
@media screen and (max-width: 1024px) {
  .articles .article-wrapper .article-block .content-wrapper .quote-wrapper.has-author-photo {
    padding-left: 25px;
    padding-top: 180px;
    margin-left: 0;
    margin-top: 150px;
  }
}
@media screen and (max-width: 1024px) {
  .articles .article-wrapper .article-block .content-wrapper .quote-wrapper.has-author-photo::before {
    top: 120px;
  }
}
.articles .article-wrapper .article-block .content-wrapper .quote-wrapper.has-author-photo .author {
  position: absolute;
  left: -100px;
  top: calc(50% - 100px);
  width: 200px;
  height: auto;
  opacity: 0;
}
@media screen and (max-width: 1024px) {
  .articles .article-wrapper .article-block .content-wrapper .quote-wrapper.has-author-photo .author {
    top: -100px;
    left: 50%;
    transform: translateX(-50%);
  }
}
.articles .article-wrapper .article-block .content-wrapper .brand-logo {
  width: 400px;
  margin: 0 auto;
}
@media screen and (max-width: 500px) {
  .articles .article-wrapper .article-block .content-wrapper .brand-logo {
    width: 100%;
  }
}

#container .video-block {
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);
}
#container .video-block .wrapper {
  width: 1200px;
  max-width: 100%;
  padding: 0 0 140px;
}
@media screen and (max-width: 1200px) {
  #container .video-block .wrapper {
    width: 100%;
    padding: 0 30px 140px;
  }
}
@media screen and (max-width: 768px) {
  #container .video-block .wrapper {
    width: 100%;
    padding: 0 60px 60px;
  }
}
@media screen and (max-width: 415px) {
  #container .video-block .wrapper {
    padding: 0 30px 40px;
  }
}
#container .video-block .video-responsive {
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 56.25%;
}
#container .video-block .background-text {
  font: 270px/90% "Jubilat", serif;
  font-weight: 700;
  white-space: nowrap;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@media screen and (max-width: 768px) {
  #container .video-block .background-text {
    font-size: 70px;
  }
}
#container .video-block .video-player {
  overflow: revert;
  padding-bottom: 20px;
}
#container .video-block .video-player .vjs-control-bar {
  width: auto;
  right: unset;
  left: 50%;
  transform: translate(-50%, 50%);
  background-color: #DE2628;
  border-radius: 100px;
}
@media screen and (max-width: 768px) {
  #container .video-block .video-player .vjs-control-bar {
    transform-origin: center center;
    transform: translate(-50%, 50%) scale(0.75);
  }
}
#container .video-block .video-player .vjs-control-bar .vjs-control,
#container .video-block .video-player .vjs-control-bar .vjs-time-divider {
  display: none;
}
#container .video-block .video-player .vjs-control-bar .vjs-play-control,
#container .video-block .video-player .vjs-control-bar .vjs-current-time,
#container .video-block .video-player .vjs-control-bar .vjs-volume-panel,
#container .video-block .video-player .vjs-control-bar .vjs-mute-control {
  display: initial;
}
#container .video-block .video-player .vjs-control-bar .vjs-volume-panel.vjs-hover {
  width: auto;
}
#container .video-block .video-player .vjs-control-bar .vjs-current-time {
  margin-left: 0;
}

.footer-block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 60px 24px;
  color: #fff;
  background: #090909;
  z-index: 2;
}
.footer-block__logo {
  width: 212px;
  margin-bottom: 40px;
}
.footer-block__copy {
  max-width: 800px;
  margin: 0 auto 20px;
  font-family: "Lato", sans-serif;
  font-size: 16px;
  line-height: 1.6em;
  text-align: center;
}
.footer-block__copy a {
  color: #fff;
  text-decoration: underline;
}
.footer-block__copy a:hover, .footer-block__copy a:focus {
  color: #fff;
  text-decoration: none;
}
.footer-block .social-icon-row {
  margin-bottom: 42px;
  display: flex;
}
.footer-block .social-icon {
  margin: 0px 10px;
  height: 60px;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 2px solid #DE2628;
  background-color: #DE2628;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0px 12px;
}
.footer-block .social-icon:hover {
  background-color: transparent;
  border: 2px solid #fff;
}
@media (max-width: 768px) {
  .footer-block .social-icon {
    height: 44px;
    width: 44px;
    margin: 0px 12px;
  }
  .footer-block .social-icon img {
    height: 23px;
  }
}

/*GLOBAL STYLES*/
* {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

*::-webkit-media-controls-panel {
  display: none !important;
  -webkit-appearance: none;
}

*::-webkit-media-controls-play-button {
  display: none !important;
  -webkit-appearance: none;
}

*::-webkit-media-controls-start-playback-button {
  display: none !important;
  -webkit-appearance: none;
}

html,
body {
  width: 100%;
  height: 100%;
}

body {
  letter-spacing: 0.5px;
}

.clear {
  clear: both;
}

.wrapper {
  position: relative;
  margin: 0 auto;
  width: 1440px;
  max-width: 100%;
}

#container {
  margin: 0 auto;
  background: #fff;
  overflow: hidden;
}

#container .screen-reader {
  width: 0px;
  height: 0px;
  border: 0px;
  margin: 0px;
  padding: 0px;
  position: absolute;
  font-size: 0px;
  speak: normal;
}

#maincontent {
  position: relative;
  padding-top: 60px;
  font-family: "Bilo", sans-serif;
  font-weight: 500;
}

/* Footer */
.footer {
  background: #000 !important;
}

/* HEADERS */
.global-header {
  margin-top: 0;
  border-bottom: 1px solid #e5e5e5 !important;
  -webkit-transition: 1.2s;
  transition: 1.2s;
}

.global-header.scrolling,
#progress.scrolling {
  margin-top: -72px;
}

#secondary-header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 60px;
  font-family: "din-2014", "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-align: center;
  color: #fff;
  background: #090909;
  border-bottom: 1px solid #3f3f3f;
  z-index: 9999;
}

#secondary-header a,
#secondary-header a:hover {
  color: #fff;
}

#secondary-header.fixed {
  position: fixed;
}

.secondary-sponsored {
  display: block;
  position: relative;
  width: 100%;
  height: 20px;
  font-size: 12px;
  font-weight: 700;
  line-height: 20px;
  text-transform: uppercase;
  color: #000;
  background: #f5f5f5;
}

#secondary-header .secondary-sponsored a,
#secondary-header .secondary-sponsored a:hover {
  color: #000;
}

.mobile-show {
  display: block;
}

.crafted {
  display: inline-block;
  position: absolute;
  top: 20px;
  left: 15px;
  font-size: 14px;
  line-height: 40px;
  font-weight: 400;
  text-align: left;
  text-transform: uppercase;
  color: #fff;
}

.crafted span {
  font-weight: 700;
}

.crafted .logo {
  display: inline-block;
  width: 150px;
  height: 13px;
  margin-left: 0.5em;
}

.secondary-presented {
  display: inline-block;
  position: relative;
  width: 79px;
}

.secondary-presented .logo {
  margin-top: 6px;
  width: 100%;
}

/* Social */
#social {
  position: absolute;
  top: 20px;
  right: 0;
  height: 40px;
  font-size: 14px;
  font-weight: 800;
  text-align: center;
  z-index: 10;
  display: flex;
}

#social .share {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 39px;
  text-align: center;
  border-left: 1px solid #3f3f3f;
  /* Iphone 5 issues */
}
@media (max-width: 320px) {
  #social .share {
    width: 30px;
    height: 39px;
  }
}

#social svg {
  width: 18px;
  height: 18px;
  margin-top: 11px;
}

.share.facebook:hover {
  background: #3b5998;
}

.share.twitter:hover {
  background: #1da1f2;
}

.share.linkedin:hover {
  background: #0a66c2;
}

/* Progress Bar */
.progress-container {
  position: absolute;
  bottom: -4px;
  width: 100%;
  height: 4px;
}

.progress-bar {
  background: #DE2628;
  width: 0%;
  height: 4px;
}

@media screen and (max-width: 1024px) {
  .crafted {
    left: 15px;
  }
}
@media screen and (max-width: 479px) {
  .crafted {
    font-size: 10px;
  }
  .crafted span {
    font-weight: 400;
    letter-spacing: 0.2em;
  }
}