.articles {
  position: relative;
  background-color: #fff;

  .article-wrapper {
    display: none;
    flex-direction: column;
    align-items: center;
    width: 100%;
    transition: opacity 100ms ease-in-out;

    &.animating {
      opacity: 0;
    }

    &.active {
      display: flex;
    }

    .article-block {
      display: flex;
      flex-direction: column;
      width: 1000px;
      max-width: 100%;
      border-bottom: 1px solid #CFCFCF;
      position: relative;
      padding: 0 0 90px;
      z-index: 2;

      header,
      .content-wrapper {
        padding: 0 100px;

        @media screen and (max-width: 768px) {
          padding: 0 30px;
        }
      }

      header {
        position: relative;

        @media screen and (max-width: 768px) {
          padding-left: 120px;
        }

        h2 {
          font: 900 60px $fontFamilySans1;
          color: transparent;
          text-transform: uppercase;
          margin-top: 6px;
          -webkit-text-stroke-width: 1px;
          -webkit-text-stroke-color: $black;

          @media screen and (max-width: 415px) {
            font-family: $fontFamilySerif;
            font-weight: 700px;
            font-size: 28px;
            line-height: 90%;
            color: $black;
            -webkit-text-stroke-width: 0;
          }
        }

        .index {
          position: absolute;
          top: 50%;
          left: 0;
          font: 12px $fontFamilySans1;
          font-weight: 700;
          letter-spacing: 5px;
          width: 73px;
          height: 44px;
          padding: 17px 0 0 13px;
          background: url(../img/article-number.svg) no-repeat center center;
          background-size: contain;
          transform: translateY(-50%);

          @media screen and (max-width: 1024px) {
            left: 20px;
          }
        }

        .eyebrow {
          font: 12px $fontFamilySans1;
          font-weight: 700;
          text-transform: uppercase;
          letter-spacing: 5px;

          @media screen and (max-width: 768px) {
            font-size: 8px;
          }
        }

        .btn-back {
          position: absolute;
          top: -40px;
          right: 60px;
          color: $red;
          font-weight: 800;
          font-size: 12px;
          letter-spacing: 5px;
          text-transform: uppercase;

          &::before {
            content: '';
            display: inline-block;
            width: 37px;
            height: 8px;
            margin-right: 8px;
            background: center/contain url(../img/back-arrow.svg) no-repeat;
          }

          @media screen and (max-width: 768px) {
            top: -10px;
          }
        }
      }

      .content-wrapper {
        p {
          margin: 2em 0;
          line-height: 160%;

          @media screen and (max-width: 415px) {
            font-size: 14px;
          }
        }

        .quote-wrapper {
          font: 40px/120% $fontFamilySerif;
          font-weight: 400;
          margin: 76px -100px;
          position: relative;
          border: 1px solid $lightGray;
          text-align: center;
          padding: 59px 72px;

          @media screen and (max-width: 1024px) {
            padding: 40px 25px 60px;
            margin: 60px auto 80px;
          }

          &::before {
            content: '';
            position: absolute;
            top: -22px;
            left: calc(50% - 33px);
            width: 66px;
            height: 44px;
            background: #fff url(../img/quote.svg) no-repeat center center;
            background-size: contain;
          }

          p {
            margin: 0;
            line-height: 120%;
            opacity: 0;

            @media screen and (max-width: 1024px) {
              font-weight: 600;
              font-size: 22px;
              line-height: 120%;
            }
          }

          .citation {
            position: absolute;
            left: 0;
            right: 0;
            bottom: -16px;
            text-align: center;
            opacity: 0;

            @media screen and (max-width: 1024px) {
              font-size: 10px;
              line-height: 120%;
              height: 5px;
              bottom: 0;
            }

            span {
              font: 12px $fontFamilySans1;
              font-weight: 700;
              text-transform: uppercase;
              letter-spacing: 5px;
              padding: 0 20px;
              background-color: #fff;

              @media screen and (max-width: 1024px) {
                width: 90%;
                padding: 0 8px;
                display: inline-block;
              }
            }

            .dash {
              display: inline;
              color: $red;
            }
          }

          &.has-author-photo {
            font-size: 38px;
            padding-left: 200px;
            margin-left: 40px;

            @media screen and (max-width: 1024px) {
              padding-left: 25px;
              padding-top: 180px;
              margin-left: 0;
              margin-top: 150px;
            }

            &::before {
              @media screen and (max-width: 1024px) {
                top: 120px;
              }
            }

            .author {
              position: absolute;
              left: -100px;
              top: calc(50% - 100px);
              width: 200px;
              height: auto;
              opacity: 0;

              @media screen and (max-width: 1024px) {
                top: -100px;
                left: 50%;
                transform: translateX(-50%);
              }
            }
          }
        }

        .brand-logo {
          width: 400px;
          margin: 0 auto;

          @media screen and (max-width: 500px) {
            width: 100%;
          }
        }
      }
    }
  }
}
